<template>
    <div class="sectionclsbody">
        <input type="hidden" class="projectIdCls" v-bind:value="projectId" >
            <div class="serach_controls_main tsk_controls_main_cls">
                <div class="serach_controls_main_row1">
                    <div class="tsk_top_filter_left">                        
                        <div class="serach_controls me-2">
                            <SearchSvgIcon />
                            <input type="search" class="formmginput-control tsk_search" id="searchTxtId" placeholder="Search here" v-on:keyup="reloadSearch()">
                        </div>
                        <div class="serach_controls w-200 me-2 projectFilterCls">
                            <Multiselect 
                                v-model="filterProjectIds" 
                                mode="multiple" 
                                :hide-selected="false" 
                                :caret="true" 
                                :searchable="true" 
                                :create-option="true" 
                                :options="$store.state.project.ProjectFilterList" 
                                placeholder="Select Projects" 
                                v-bind:name="'filterProjectIds[]'" 
                                v-bind:id="'filterProjectIds'"
                                @change="myChangeProjectEvent($event)"  />
                        </div>
                        <div class="serach_controls w-200 me-2"> 
                            <Multiselect
                                    v-model="filterUsersIds"
                                    :caret="true" 
                                    mode="multiple"
                                    placeholder="Select Teams"
                                    :searchable="true"
                                    v-bind:name="'filterUsersIds[]'"
                                    v-bind:id="'filterUsersIds'" 
                                    track-by="label"
                                    label='label'
                                    :close-on-select="false"
                                    @change="myChangeUsersEvent($event)"
                                    :hide-selected="false"
                                    :options="$store.state.teamMember.TeamMemberImgFilterList"
                                >
                                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                    <div
                                        class="multiselect-tag is-user"
                                        :class="{
                                            'is-disabled': disabled
                                        }"
                                    >
                                        <div v-if="option.initials == null">  
                                            <img :src="img_url + '' + option.user_image">
                                            {{ option.label }}
                                        </div>
                                        <div v-else>  
                                            {{ option.initials }} {{ option.label }}
                                        </div>
                                        <span
                                        v-if="!disabled"
                                        class="multiselect-tag-remove"
                                        @mousedown.prevent="handleTagRemove(option, $event)"
                                        >
                                        <span class="multiselect-tag-remove-icon"></span>
                                        </span>
                                    </div>
                                    </template>
                                    <template v-slot:option="{ option }">
                                        <div v-if="option.initials == null">  
                                            <img class="user-image" :src="img_url + '' + option.user_image" >  {{ option.label }}
                                        </div>
                                        <div v-else>
                                            <span class="empIniName">
                                                {{ option.initials }} 
                                            </span>
                                            {{ option.label }}
                                        </div>
                                </template>
                            </Multiselect>
                        </div>                        
                        <div class="serach_controls w-200 me-2" > 
                            <Multiselect 
                                v-model="filterLabelsIds" 
                                mode="multiple" 
                                :hide-selected="false" 
                                :caret="true" 
                                :searchable="true" 
                                :create-option="true" 
                                :options="$store.state.label.LabelFilterList" 
                                placeholder="Select Labels" 
                                v-bind:name="'filterLabelsIds[]'" 
                                v-bind:id="'filterLabelsIds'"
                                @change="myChangeLabelsEvent($event)"  />
                        </div>
                    </div>
                    <div class="tsk_top_filter_right">                       
                        <div class="export_controls">
                            <a href="javascript:;" class="filterToggle" v-on:click="filterOpen()">
                                <FilterSvgIcon />
                            </a>
                        </div>
                        
                    </div>
                </div>
                <div class="serach_controls_main_row2 mt-2" style="display: none;">
                    <div class="serach_controls w-200 me-2"> 
                        <Multiselect 
                            v-model="filterPriorityIds" 
                            mode="multiple" 
                            :hide-selected="false" 
                            :caret="true" 
                            :searchable="true" 
                            :create-option="true" 
                            :options="$store.state.priority.PriorityFilterList" 
                            placeholder="Select Priority" 
                            v-bind:name="'filterPriorityIds[]'" 
                            v-bind:id="'filterPriorityIds'"
                            @change="myChangePriorityEvent($event)"  />
                    </div>
                    <div class="serach_controls w-230"> 
                        <Multiselect 
                            v-model="filterStatusIds" 
                            mode="multiple" 
                            :hide-selected="false" 
                            :caret="true" 
                            :searchable="true" 
                            :create-option="true" 
                            :options="$store.state.board.BoardFilterList" 
                            placeholder="Select Board Status" 
                            v-bind:name="'filterStatusIds[]'" 
                            v-bind:id="'filterStatusIds'"
                            @change="myChangeStatusEvent($event)"  />
                    </div>
                </div>
            </div>
        <div class="card-body p-0">
            <div class="table-responsive pageTblHeightCls">
                <table class="table table-bordered tsk_list_mnc archivelistbotbl" id="dataTable" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Ticket</th>
                            <th>Title</th>
                            <th>Project</th>
                            <th>Last Updated At</th>
                            <th>Assign to</th>
                            <!-- <th>Priority</th> -->
                            <th>Labels</th>
                            <th>Board Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody class="tbodyCls shimmer-wrapper" v-if="tableLoader == 1">
                        <!-- <tr class="text-center">
                                <td colspan="7">
                                    <ComponentLoader />
                                </td> 
                        </tr> -->
                        <tr class="text-center" v-for="index in 15" :key="index">
                            <td v-for="index1 in 8" :key="index1"><span class="shimmer-line shimmer-line-br shimmer-line-full shimmer-animate"></span></td>
                        </tr>
                    </tbody>
                    <tbody class="tbodyCls" v-else-if="tableLoader === 2">
                        <ComponentNoData />
                        <!-- <tr class="text-center">
                            <td colspan="7">
                            </td>
                        </tr> -->
                    </tbody>
                    <tbody class="tbodyCls" v-else>
                        <tr v-for="(item) in taskList" :key="item._id" v-bind:class="removeSpace(item.boardstatus)">
                            <td v-bind:title="item.priority">
                                <!-- <span class="tsk_priority_clsX" :style="{ background: item.color }"></span> -->
                                <div class="tsk_tickets">
                                    <span class="ticLisCls">#{{ item.task_no }}</span>
                                    <!-- <span class="priLisCls" v-bind:class="'priority-cls-'+item.priority">{{ item.priority }}</span> -->
                                </div>
                            </td>
                            <td>
                                <a href="javascript:;" class="btn-link" v-bind:id="item._id" v-on:click="modalViewOpen(item._id)" v-bind:title="item.title" v-bind:class="'kanTitCls-'+item._id">{{ item.title }}</a>                                    
                            </td>
                            <td>
                                <!-- <router-link :to="`/project/${item.project_id}/overview`" class="" exact> -->
                                <router-link :to="`/project/${item.project_id}/task-list`" class="" exact>
                                    {{ item.projects }}
                                </router-link>
                            </td>
                            <td >{{ formatDate(item.updatedAt) }}</td>
                            <td>
                                <div  v-bind:class="'kanUsersCls-'+item._id">
                                    <div class="tsk_assign_user" v-html="userImgHtml(item.users)" />     
                                </div>
                                <!-- <div class="tsk_assign_user" v-html="userImgHtml(item.users)" />  -->
                            </td>
                            <!-- 
                            <td>
                                <div class="tsk_priority_main" >
                                    <div class="tsk_priority" :style="{ background: item.color }" >
                                        <span title="Upcoming" class="labelSpanCls">{{ item.priority }}</span>
                                    </div>
                                </div>  
                                <div class="kanPriNameMainCls" v-bind:class="'kanPriCls-'+item._id">
                                    <div class="kanPriNameCls" v-if="item.priority">
                                        <div :style="{ background: item.color }">{{ item.priority }}</div>     
                                    </div>    
                                </div>                                  
                            </td>
                            -->
                            <td>
                                <div class="tsk_priority_main" v-bind:class="'kanLabelCls-'+item._id"
                                 v-html="labelsHtml(item.labels)" /> 
                            </td>
                            <td v-bind:class="removeSpace(item.boardstatus)">{{ item.boardstatus }}</td>
                            <td>
                                <!-- <a href="javascript:;" class="cls_action d-none" v-bind:id="item._id" v-on:click="modalEditOpen(item._id)" title="Edit">
                                    <ViewSvgIcon />
                                </a>
                                <a href="javascript:;" class="cls_action" v-bind:id="item._id" v-on:click="modalViewOpen(item._id)" v-b-tooltip.hover title="View">
                                    <EditSvgIcon />
                                </a>
                                <a href="javascript:;" class="cls_action" v-bind:id="item._id" v-on:click="modalDeleteOpen(item._id)" v-b-tooltip.hover title="Delete">
                                    <DeleteSvgIcon />
                                </a>
                                <a href="javascript:;" class="cls_action" v-bind:id="item._id" v-on:click="restoreTask(item._id)" v-b-tooltip.hover title="Back to task">
                                    <RestoreSvgIcon />
                                </a> -->
                                <vs-dropdown vs-trigger-click>
                                    <a class="a-icon ncw_iconsquare" href="#">
                                        <vs-icon class="" icon="more_vert"></vs-icon>
                                    </a>
                                    <vs-dropdown-menu>
                                        <vs-dropdown-item class="cls_action d-none" v-bind:id="item._id" v-on:click="modalEditOpen(item._id)" title="Edit">
                                            <ViewSvgIcon />
                                            <span>View Task</span>
                                        </vs-dropdown-item>
                                        <vs-dropdown-item class="cls_action clsedit" v-bind:id="item._id" v-on:click="modalViewOpen(item._id)" v-b-tooltip.hover title="View">
                                            <EditSvgIcon />
                                            <span>Edit Task</span> 
                                        </vs-dropdown-item>
                                        <vs-dropdown-item class="cls_action" v-bind:id="item._id" v-on:click="modalDeleteOpen(item._id)" v-b-tooltip.hover title="Delete" v-if="$store.state.user.role_name != 'Users'">
                                            <DeleteSvgIcon />
                                            <span>Delete Task</span>
                                        </vs-dropdown-item>
                                        <vs-dropdown-item class="cls_action" v-bind:id="item._id" v-on:click="restoreTask(item._id)" v-b-tooltip.hover title="Back to task">
                                            <RestoreSvgIcon />
                                            <span>Restore Task</span>
                                        </vs-dropdown-item>
                                    </vs-dropdown-menu>
                                </vs-dropdown>
                            </td>
                        </tr>
                    </tbody>
                </table> 
            </div>
            <div class="d-flex px-3 databottom justify-content-between align-content-center" v-if="tableLoader == 3">
                <p v-if="page * pageLength > totalRecord">Showing {{ formatMoney((page * pageLength) - pageLength + 1) }} to {{
                    formatMoney(totalRecord) }} of {{ formatMoney(totalRecord) }} entries</p>
                <p v-else>Showing {{ formatMoney((page * pageLength) - pageLength + 1) }} to {{ formatMoney(page * pageLength) }} of {{
                    formatMoney(totalRecord) }} entries</p>
                <vs-pagination :total="totalLenght" :max="8" v-model="page" @click="reloadPagination(page)"></vs-pagination>
            </div>
        </div>
    </div>
    <div>  
        <AddEditLabel @reload-table-data="reloadSearch"/>
    </div>
    <div class="">  
        <AddEditTask @reload-table-data="reloadSearch" ref="childComponentTaskRef"/>
        <PreviewTask @reload-table-data="reloadSearch" ref="childComponentPreviewTaskRef"/>
    </div>
    <input type="hidden" class="taskValId" id="taskValId"/>
    <a href="javascript:;" class="editTasksBtns" ></a>
    <div>
    </div>
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#deleteArchiveModals" id="archiveTaskBtn"> </button>
    <div class="modal fade confirmationset" id="deleteArchiveModals" tabindex="-1" style="display: none;" aria-hidden="true"  data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header justify-content-center">
                <h5 class="modal-title">Delete Task?</h5> 
            </div>
            <div class="modal-body"> 
                <div class="col-12 text-center"> 
                <label for="bankName" class="form-label mb-0">All actions will be removed from the activity feed and you won’t be able to re-open the card. There is no undo.</label> 
                </div>
            </div>
            <div class="modal-footer justify-content-center d-flex">
                <button variant="success"  class="mg-btn-gold h-30 me-2" v-on:click="deleteItem()">Delete</Button> 
                <button variant="outline-primary" class="mg-btn-delete-lightx h-30 md closeArchiveBtn" id="closeArchiveBtn" data-bs-dismiss="modal" >Cancel</Button>         
            </div>
            </div>
        </div>
        </div>
</template>
<script>
import Multiselect from '@vueform/multiselect'
import AddEditTask from './List/AddEditTask.vue'
import AddEditLabel from '../Label/AddEditLabel.vue'
import PreviewTask from './List/PreviewTask.vue'
// import ComponentLoader from '../Widgets/Icon/ComponentLoader.vue'
import ComponentNoData from '../Widgets/Icon/ComponentNoData.vue'
import EditSvgIcon from '../Widgets/Icon/EditSvgIcon.vue'
import ViewSvgIcon from '../Widgets/Icon/ViewSvgIcon.vue'
import FilterSvgIcon from '../Widgets/Icon/FilterSvgIcon.vue'
import SearchSvgIcon from '../Widgets/Icon/SearchSvgIcon.vue'
import DeleteSvgIcon from '@/components/Widgets/Icon/DeleteSvgIcon'
import RestoreSvgIcon from '@/components/Widgets/Icon/RestoreSvgIcon'
import dayjs from 'dayjs';
import axios from 'axios'
import $ from "jquery";
import {
    formatMoney
} from '../../helper'
import {
    ref
} from 'vue';
export default {
    name: 'TasksArchivePage',
    components: {
        AddEditTask,
        AddEditLabel,
        PreviewTask,
        // ComponentLoader,
        RestoreSvgIcon,
        ComponentNoData,
        Multiselect,
        EditSvgIcon,
        ViewSvgIcon,
        FilterSvgIcon,
        SearchSvgIcon,
        DeleteSvgIcon,
    },
    props: {
        projectId: {
            type: String,
        },
        msg: String
    },
    data() {
        return {
            img_url: process.env.VUE_APP_IMG_URL,
            currentx: 5,
            tableLoader: 1,
            page: 1,
            pageLength: this.$store.state.showEntries,
            searchKey: '',
            totalRecord: 0,
            totalLenght: 0,
            filterTemp: 0,
            taskList: [],
            filterProjectIds: [],
            filterPriorityIds: [],
            filterLabelsIds: [],
            filterStatusIds: [],
            filterUsersIds: [],
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            taskListHeight: 0,
            taskMainListHeight: 0,
        }
    },
    async mounted() {
        this.$store.state.pageTitle = 'Task Archive'
        if ($('.projectIdCls').val().length !== 0) {
            $('.projectFilterCls').hide();
        }
        if (this.$store.state.isActiveToken != '1') {
            // console.log(this.currentDate);
        } else {
            await this.loadTaskData();
        }
        let temps = this
        $("body").on("click", '#searchTxtId', function () {
            if ($('#searchTxtId').val() != '') {
                $('#searchTxtId').val('');
                this.searchKey = '';
                this.page = 1;
                temps.reloadSearch()
            }
        });
        $("body").on("click", '.editTasksBtns', function () {
            var task_id = $('.taskValId').val();
            temps.modalEditOpen(task_id);
        });
        this.onResize();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })  
    },
    computed: {
        iAT() {
            return this.$store.state.user.roleId
        },
    },
    watch: {
        async iAT(newValue) {
            if (this.$store.state.user.roleId != '') {
                await this.loadTaskData();
            }
            return newValue
        },
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        formatMoney,
        formatDate(dateTimeString) {
            return dayjs(dateTimeString).format('DD-MM-YYYY hh:mm A');
        },
        startLoader() {
            this.$Progress.start()
        },
        finishLoader() {
            this.$Progress.finish()
        },
        removeSpace(string) {
            var str = string.replace(/\s/g, '');
           str = str.toLowerCase() +'-Cls'
            // console.log(string)
            return str
        },
        checkHeightScroll() {
            $('.table').removeClass('ScrollAddedCls');
            if (this.hasScrollBar() == true) {
                $('.table').addClass('ScrollAddedCls');
            }
        },
        hasScrollBar() {
            if ($(".tbodyCls")[0]) {
                return $('.tbodyCls').get(0).scrollHeight > $('.tbodyCls').height() + 10;
            } else {
                return false;
            }
        },
        onResize() {
            this.windowWidth = window.innerWidth
            this.windowHeight = window.innerHeight

            var headNavCls = $('.headNavCls').outerHeight() || 0;
            var tsk_tabs = $('.tsk_tabs').outerHeight() || 0;
            var tsk_controls_main_cls = $('.tsk_controls_main_cls').outerHeight() || 0;
            var thead = $('thead').outerHeight() || 0;
            var databottom = $('.databottom').outerHeight() || 0;

            var cntSubvar = headNavCls + tsk_tabs + tsk_controls_main_cls + thead + databottom
            var tabHei = headNavCls + tsk_tabs + tsk_controls_main_cls + databottom


            cntSubvar = this.windowHeight - cntSubvar - 30
            tabHei = this.windowHeight - tabHei - 30

            this.taskMainListHeight = tabHei;
            $('.pageTblHeightCls').height(tabHei);
            $('tbody').height(cntSubvar);
            this.checkHeightScroll();
            // $('.sectionbody').removeClass('firstHeightCls')

        },
        async filterOpen() {
            $('.serach_controls_main_row2').slideToggle()
            $('.filterToggle').toggleClass('active')
            // await this.onResize()
            setTimeout(async () =>
                this.onResize(),
            500);
        },
        async reloadPagination(itemObjKey) {
            if (itemObjKey > 0 && itemObjKey !== -1 && itemObjKey <= this.totalLenght) {
                this.page = itemObjKey;
                this.loadTaskData();
            }
        },
        userImgHtml(users) {
            var len = users.length;
            var htmls = '';
            for (var i = 0; i < len; i++) {
                if(len <= 3 || i < 2){
                    if(users[i].initials == null){
                        htmls += '<div class="mg-thumbnail">';
                        htmls += '<img src="'+this.img_url + '' + users[i].user_image+'" alt="'+users[i].name+'" title="'+users[i].name+'" />';
                        htmls += '</div>';
                    }else{
                        htmls += '<div class="mg-thumbnail tsk_name">';
                        htmls += '<span title="'+users[i].name+'">'+users[i].initials+'</span>';
                        htmls += '</div>';
                    }
                }                        
            }
            if(len > 2 && len > 3){
                htmls += '<div class="mg-thumbnail tsk_number">';
                    var cntVal = len-2;
                    htmls += '+'+cntVal;
                htmls += '</div>';
            }
            return htmls;
        },
        labelsHtml(labels) {
            var len = labels.length;
            var htmls = '';
            for (var i = 0; i < len; i++) {
                    htmls += '<div class="tsk_priority" style="background-color: ' + labels[i].color_code + '">';
                        htmls += '<span title="'+labels[i].label+'" class="labelSpanCls">'+labels[i].label+'</span>';
                    htmls += '</div>';
            }
            return htmls;
        },
        myChangeProjectEvent(val) {
            this.filterProjectIds = val
            this.page = 1;
            this.loadTaskData();
        },
        myChangePriorityEvent(val) {
            this.filterPriorityIds = val
            this.page = 1;
            this.loadTaskData();
        },
        myChangeLabelsEvent(val) {
            this.filterLabelsIds = val
            this.page = 1;
            this.loadTaskData();
        },
        myChangeStatusEvent(val) {
            this.filterStatusIds = val
            this.page = 1;
            this.loadTaskData();
        },
        myChangeUsersEvent(val) {
            this.filterUsersIds = val
            this.page = 1;
            this.loadTaskData();
        },
        async reloadSearch() {
            const searchCls = document.getElementById('searchTxtId').value;
            this.searchKey = searchCls.trim();
            this.page = 1;
            this.loadTaskData();
        },
        async loadTaskData() {
            this.tableLoader = 1
            let token = localStorage.getItem('mgTasktoken');
            if (!token) {
                this.$store.state.user.accessToken = ''
            }
            var filterProjectIds = []
            if ($('.projectIdCls').val().length !== 0) {
                filterProjectIds.push(this.projectId);
            } else {
                filterProjectIds = this.filterProjectIds
            }
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.get(process.env.VUE_APP_API_URL + 'task/list', {
                    params: {
                        page: this.page,
                        pageLength: this.pageLength,
                        searchKey: this.searchKey,
                        projectIds: filterProjectIds,
                        priorityIds: this.filterPriorityIds,
                        labelIds: this.filterLabelsIds,
                        statusIds: this.filterStatusIds,
                        userIds: this.filterUsersIds,
                        is_deleted: 2,
                    }
                })
                .then(async (resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.taskList = []
                        this.taskList = resp.data.data.records
                        this.totalRecord = resp.data.data.totalRecord
                        this.totalLenght = this.totalRecord / this.pageLength
                        this.totalLenght = Math.ceil(this.totalLenght);
                        if (this.taskList.length > 0) {
                            this.tableLoader = 3
                        } else {
                            this.tableLoader = 2
                        }
                        // await this.onResize()
                        $('tbody').height(this.taskListHeight),
                        setTimeout(async () =>
                            this.onResize(),
                        100);
                    }else{
                        this.$store.state.user.accessToken = ''
                        this.$store.state.isActiveToken = ''
                        this.$store.state.user.userInfo = ''
                        localStorage.removeItem("userInfo");
                        localStorage.removeItem("mgTasktoken");
                        this.$router.push({
                            name: 'Login'
                        })
                    }
                })
                .catch(error => {
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    // this.errorRes = error.response.data.message;
                })
        },
        async modalEditOpen(task_id) {
            $('.taskValId').val(task_id);
            this.callChildProjectMethod();
        },
        async modalViewOpen(task_id) {
            $('.taskValId').val(task_id);
            this.callChildPreviewMethod();
        },
        async modalDeleteOpen(task_id) {
            $('.taskValId').val(task_id);
            document.getElementById('archiveTaskBtn').click();
        },
        async deleteItem() {
            this.startLoader();
            const task_id = $('.taskValId').val();
            let _URL = ''
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            let _post = {}
            _URL = process.env.VUE_APP_API_URL + 'task/delete'
            _post = {
                task_id: task_id,
            }
            await axios.post(_URL, _post)
            .then(async (resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    document.getElementById('closeArchiveBtn').click();
                    this.finishLoader()
                    this.loadTaskData();
                } else if (resp.status == 200 && resp.data.status == 'failure') {
                    this.errorMessage = resp.data.message;
                }
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
            }) 
        },
        async restoreTask(task_id) {
            this.startLoader();
            let _URL = ''
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            let _post = {}
            _URL = process.env.VUE_APP_API_URL + 'task/restore'
            _post = {
                task_id: task_id,
            }
            await axios.post(_URL, _post)
            .then(async (resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    this.finishLoader()
                    this.loadTaskData();
                } else if (resp.status == 200 && resp.data.status == 'failure') {
                    this.errorMessage = resp.data.message;
                }
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
            }) 
        },
    },
    setup() {
        const childComponentTaskRef = ref(null);
        const callChildProjectMethod = () => {
            if (childComponentTaskRef.value) {
                childComponentTaskRef.value.modalEditOpen();
            }
        };

        const childComponentPreviewTaskRef = ref(null);
        const callChildPreviewMethod = () => {
            if (childComponentPreviewTaskRef.value) {
                childComponentPreviewTaskRef.value.modalViewOpen();
            }
        };
        const visibleRef = ref(false)
        const indexRef = ref(0) // default 0
        const imgsRef = ref([])
        // Img Url , string or Array of string
        // ImgObj { src: '', title: '', alt: '' }
        // 'src' is required
        // allow mixing

        const onShow = () => {
            visibleRef.value = true
        }
        const showSingle = () => {
            imgsRef.value = 'http://via.placeholder.com/350x150'
            // or
            // imgsRef.value  = {
            //   title: 'this is a placeholder',
            //   src: 'http://via.placeholder.com/350x150'
            // }
            onShow()
        }
        const showMultiple = () => {
            imgsRef.value = [
                'http://via.placeholder.com/350x150',
                'http://via.placeholder.com/350x150'
            ]
            // or
            // imgsRef.value = [
            //   { title: 'test img', src: 'http://via.placeholder.com/350x150' },
            //   'http://via.placeholder.com/350x150'
            // ]
            indexRef.value = 0 // index of imgList
            onShow()
        }
        const onHide = () => (visibleRef.value = false)

        return {
            callChildProjectMethod,
            childComponentTaskRef,
            callChildPreviewMethod,
            childComponentPreviewTaskRef,
            visibleRef,
            indexRef,
            imgsRef,
            showSingle,
            showMultiple,
            onHide
        };
    },
}
</script>
<style scoped>
.mg-thumbnail span.labelSpanCls{
    color:  white;
}
.multiselect-tag.is-user {
    padding: 5px 8px;
    border-radius: 22px;
    background: #35495e;
    margin: 3px 3px 8px;
}

.multiselect-tag.is-user img {
    width: 18px;
    border-radius: 50%;
    height: 18px;
    margin-right: 8px;
    border: 2px solid #ffffffbf;
}

.multiselect-tag.is-user i:before {
    color: #ffffff;
    border-radius: 50%;
    ;
}

.user-image {
    margin: 0 6px 0 0;
    border-radius: 50%;
    height: 22px;
}
</style>